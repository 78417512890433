import * as React from "react"
import {Link} from "gatsby"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <div className="container page-not-found">
      <div className="row justify-content-center">
      <h2>The page you are looking for does not exist.</h2>
      </div>
      <div className="row justify-content-center">
      <p>You might want to try these instead:</p>
      <ul>
        <li><Link to="/">Go Home</Link></li>
        <li><Link to="/how-to-vote">How I Can Vote</Link></li>
        <li><Link to="/become-a-councillor">Stand for the City of London Corporation</Link></li>
      </ul>
      </div>
    </div>
  </>
)

export default NotFoundPage
